@media (max-width: 1400px) and (min-width: 1201px) {
	.chart-options {
		.btn-group {
			float: none!important;
		}
	}
}
@media (max-width: 480px) {
	.chart-options {
		.btn-group {
			float: none!important;
		}
	}
}

