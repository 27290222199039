body {
  min-width: 320px;
  background-image: url('/images/bg.png');
  background-position: -170px calc(100% + 260px);
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.bg-gray {
  background-color: #f1f1f1;
}
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.colapse-to-icons {
  .nav-link {
    span {
      display: inline-block;
      max-width: 50px;
      vertical-align: bottom;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@media (max-width: 1300px) {
  .colapse-to-icons {
    .nav-link {
      span {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .colapse-to-icons {
    .nav-link {
      span {
        display: inline-block;
        max-width: none;
      }
    }
  }
}

@media (min-width: 1530px) {
  .colapse-to-icons {
    .nav-link {
      span {
        max-width: none;
      }
    }
  }
}

.content-box {
  background: #fff;
  border-bottom: 2px solid $gray-300;
}

.dl-columns {
  columns: 6 200px;
  margin: -0.5rem 0;
}

.form-control:disabled,
.form-check-input:disabled,
.select2-container--disabled {
  cursor: not-allowed;
  pointer-events: all !important;

  .select2-selection {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
}

dd {
  overflow: hidden;
}
dt {
  overflow: hidden;
}

.h-100vh {
  height: 100vh;
}

/*
.btn-xs, .btn-group-xm > .btn {
	padding: 0.15rem 0.3rem;
	font-size: 0.7rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}
.datagrid .table td {
	height: 21.4px;
}
.header {

	width: 100%;

	.title {
		font-size: 40px;
		float: left;
	}

	.add {
		float: right;
	}

}

.rmaShow-description-content,
.rmaShow-reason-content {
	overflow-wrap: break-word;
}

*/

.card-columns {
  column-count: 1;
  @include media-breakpoint-up(lg) {
    column-count: 2;
  }
  @include media-breakpoint-up(xl) {
    column-count: 3;
  }
}
.nav-tabs {
  .nav-link.active {
    border-color: #dee2e6;
    border-bottom-color: $white;
  }
}
.card--tabs-expanded {
  .tab-content {
    margin-left: -1.25rem!important;
    margin-bottom: -1.25rem!important;
    margin-right: -1.25rem!important;
    padding-left: 1.25rem!important;
    padding-right: 1.25rem!important;
  }
  .nav-tabs {
    margin-left: -1.25rem!important;
    margin-right: -1.25rem!important;
    padding-left: 1.25rem!important;
  }
}

.text-extra-muted {
  color: #d3d3d3;
}
