.datagrid {
  padding: 0 1rem;
  background-color: transparent;
	th,
	td {
		white-space: nowrap;
	}
}

.datagrid .row-grid-bottom {
  border: none;
  border-bottom: 2px solid $gray-300;
}

.datagrid .datagrid-toolbar {
  margin-top: 0;
}

.datagrid tbody tr {
  cursor: pointer;
  &:hover .grid-main-link {
	text-decoration: underline;
	color: darken($primary, 10%);
  }
}

.datagrid .bootstrap-select.input-sm>.btn {
  padding: .25rem .5rem;
  height: 100%;
  font-size: inherit;
}

.datagrid-filters {
  margin-bottom: .5rem;
  padding: .5rem 0;
  border-bottom: 2px solid $gray-300;
}

.grid-main-link {
  position: relative;
  & i {
	position: relative;
	padding-right: .4rem;
	text-decoration: none;
  }
}

.datagrid .datagrid-row-columns-summary {
	td,
	td:first-child,
	td:last-child {
		border: none;
		border-top: 2px solid $gray-300;
	}
}
