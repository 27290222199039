label {
  font-weight: 600;
}

dl {
  break-inside: avoid;
  margin-bottom: 0;
  padding: 0.5rem 0;
}

dd {
  margin-bottom: 0;
  min-height: 1.5rem;
}

.form-control:focus {
  border-color: $input-border-color;
}


.breadcrumb {
  margin-bottom: 0;
  border-bottom: 2px solid $gray-300;
}

.navbar-brand {
  padding: 0;
}

.nav-link.btn {
  text-align: left;
}

.navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .dropdown.btn-group:hover .nav-link,
.navbar-dark .dropdown.btn-group:focus .nav-link {
  background-color: darken($primary, 5%);
  color: $white;
}

.navbar-dark .dropdown-toggle::after {
  color: $white;
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .dropdown.btn-group.active .nav-link{
  background-color: darken($primary, 10%);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  background-color: rgba($white, 0.5);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: $white;
  // Pokud by se ty taby používaly i na bílem pozadí, tak je nutné odkomentovat následující řádek
  // a pro taby přímo na šedém pozadí by se to muselo odlišit nějakou třídou.
  //border-color: $gray-300 $gray-300 $white;
  border-color: $white;
}


.dropdown-menu {
  border-color: $input-border-color;
  margin-top: -1px;
}

@media (max-width: map-get($grid-breakpoints, "xl") - 1) {
  .dropdown-menu {
	width: 100%;
  }

  .dropdown.btn-group {
	flex-wrap: wrap;
	.nav-link:first-child {
	  flex: 1;
	}
  }
}

label.small {
  margin-bottom: .2rem;
}

.table thead th {
  border-top: none;
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
  .dropdown.btn-group,
  .navbar-nav .nav-link {
	margin: 0 .2rem;
  }
}

.dropdown-toggle::after {
  border-top: 5px solid;
  border-right: 4px solid transparent;
  border-bottom: 0;
  border-left: 4px solid transparent;
  color: $gray-600;
  .dropup & {
    border-top: 0;
    border-right: 4px solid transparent;
    border-bottom: 5px solid;
    border-left: 4px solid transparent;
  }
}

.btn-primary.dropdown-toggle::after,
.btn-secondary.dropdown-toggle::after {
  color: $white;
}
