.bootstrap-select {
  .btn {
	border: 1px solid $input-border-color;
  }
}

.form-container {
  max-width: 480px;
  background: #fff;
  border-bottom: 2px solid $gray-300;
  padding-top: 1rem;
  margin-bottom: 2rem;
  fieldset {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.form-container__double-width {
  max-width: 960px;
}

.form-buttons {
  text-align: right;
  margin: 1rem 0;
}
