/**
 * Bootstrap Select
 */
.bootstrap-select .btn {
  background-color: transparent;
  height: 38px;
}
.bootstrap-select .dropdown-toggle:active,
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select.show > .dropdown-toggle,
.bootstrap-select.show > .dropdown-toggle {
  outline: none !important;
  box-shadow: $input-focus-box-shadow !important;
  background-color: $white !important;
}

.bootstrap-select.show > .dropdown-toggle:hover {
  color: $body-color;
}
.bootstrap-select .disabled {
  background-color: #e9ecef;
}

/**
 * Select2
 */
.select2-dropdown {
  border-radius: $border-radius !important;
  border: 1px solid $input-border-color !important;
}
.select2-container--default {
  width: 100% !important;
  &.select2-container--open .select2-selection--multiple,
  &.select2-container--open .select2-selection--single {
    border-radius: $border-radius !important;
  }
  &.select2-container--open .select2-dropdown--below {
    top: -1px;
  }

  .select2-selection {
    border-color: $input-border-color;
    transition: $input-transition;
  }
  &.select2-container--focus .select2-selection {
    border-color: $input-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
  }

  .select2-selection--single {
    height: 38px;
    .select2-selection__rendered {
      line-height: 36px;
      padding-right: 28px;
    }
    .select2-selection__arrow {
      height: 36px;
      width: 30px;
      b {
        border-color: $gray-600 transparent transparent transparent;
      }
    }
    .select2-selection__clear {
      line-height: 35px;
      margin-left: .5rem;
    }
  }

  .select2-selection--multiple {
    .select2-selection__choice {
      background-color: $gray-200;
      border-color: $input-border-color;
    }
  }

  .select2-results__option[aria-selected=true] {
    background-color: $gray-200;
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
  }

  .select2-search--dropdown .select2-search__field {
    border-radius: $border-radius;
    border-color: $input-border-color;
    transition: $input-transition;
    &:focus {
      outline: 0;
      box-shadow: $input-focus-box-shadow;
    }
  }
}
