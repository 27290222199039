$white: #fff;
$gray-200: #e9ecef;
$green: #00b300;
$red: #cc1010;

$primary: $green;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;

$navbar-dark-color: $white;
$breadcrumb-bg: $white;
$body-bg: $gray-200;

$label-margin-bottom: .4rem;

$fast-transition: .3s;

%font-awesome {
  font-family: 'Font Awesome 5 Free';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
